<!--
 * @Description: 审核售后单
 * @Author: 琢磨先生
 * @Date: 2022-09-02 05:24:37
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2023-05-31 11:01:12
-->
<template>
  <el-button type="primary" link size="small" @click="onOpen">审核</el-button>
  <el-dialog
    title="售后单审核"
    v-model="visible"
    width="1000px"
    draggable
    :append-to-body="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <div v-loading="loading">
      <el-descriptions title="订单详情">
        <el-descriptions-item label="单号">{{model.refund?.order.order_no}}</el-descriptions-item>
        <el-descriptions-item label="商品">{{model.refund?.order.goods_title}}</el-descriptions-item>
        <el-descriptions-item label="规格">{{model.refund?.order?.sku?.spec_values}}</el-descriptions-item>
        <el-descriptions-item label="数量">x{{ model.refund?.order.number }}</el-descriptions-item>
        <el-descriptions-item label="订单状态">{{ model.refund?.order.status_text }}</el-descriptions-item>
        <el-descriptions-item label="快递" :width="300">
          <span>{{model.refund?.order.waybill_no}}</span>
          <el-popover placement="bottom" :width="600" v-if="model.refund?.order.is_deliver">
            <template #reference>
              <el-link :underline="false" type="primary">物流信息</el-link>
            </template>
            <div style="height:400px;">
              <el-scrollbar>
                <div class="waybill" v-for="item in model.refund.order.waybill_list" :key="item.id">
                  <div class="no">
                    <b>{{item.company_name}}、{{item.waybill_no}}</b>
                  </div>
                  <el-alert type="info" :closable="false" title>
                    <el-timeline v-if="item.track_list.length>0">
                      <el-timeline-item
                        v-for="(activity, index) in item.track_list"
                        :key="index"
                        :timestamp="activity.ftime"
                      >{{ activity.context }}</el-timeline-item>
                    </el-timeline>
                    <div v-else>
                      <span>暂无物流信息</span>
                    </div>
                  </el-alert>
                </div>
              </el-scrollbar>
            </div>
          </el-popover>
        </el-descriptions-item>
        <el-descriptions-item label="商品金额">￥{{ model.refund?.order.decimal_amount }}</el-descriptions-item>
        <el-descriptions-item label="收件人">￥{{ model.refund?.order.consignee_address }}</el-descriptions-item>
        <el-descriptions-item label="邮寄地址">￥{{ model.refund?.order.consignee_address }}</el-descriptions-item>
        <el-descriptions-item label="下单时间">{{ model.refund?.order.create_at }}</el-descriptions-item>
      </el-descriptions>
      <el-descriptions title="售后">
        <el-descriptions-item label="售后类型">{{model.refund?.type_text}}</el-descriptions-item>
        <el-descriptions-item label="售后状态">{{model.refund?.status_text}}</el-descriptions-item>
        <el-descriptions-item label="退款金额">￥{{model.refund?.decimal_amount}}</el-descriptions-item>
        <el-descriptions-item label="原因">{{model.refund?.cause}}</el-descriptions-item>
        <el-descriptions-item label="备注说明">{{model.refund?.remark??''}}</el-descriptions-item>
        <el-descriptions-item label="审核人">{{model.refund?.check_emp_name??''}}</el-descriptions-item>
        <el-descriptions-item label="审核结果">{{model.refund?.check_remark??''}}</el-descriptions-item>
        <el-descriptions-item label="创建时间">{{model.refund?.create_at}}</el-descriptions-item>
      </el-descriptions>
      <el-divider>售后申请附件</el-divider>
      <div>
        <el-row>
          <el-image
            style="width: 100px; height: 100px"
            :src="url"
            v-for="(url) in model.refund?.pic_urls"
            :key="url"
            :preview-src-list="model.refund.pic_urls"
            fit="cover"
          ></el-image>
        </el-row>
      </div>
    </div>

    <el-descriptions title="退款单">
      <el-descriptions-item label="状态">{{model.status_text}}</el-descriptions-item>
      <el-descriptions-item label="退款金额">￥{{model.decimal_amount}}</el-descriptions-item>
      <el-descriptions-item label="创建时间">{{model.refund?.create_at}}</el-descriptions-item>
    </el-descriptions>

    <el-alert
      class="mall_refund_alert"
      type="info"
      :closable="false"
      title="审核结果"
      style="margin-top:20px;"
    >
      <el-form :model="form" ref="form" :rules="rules" label-width="100px">
        <el-form-item label="结果" prop="result">
          <el-radio-group v-model="form.result">
            <el-radio :label="1">同意</el-radio>
            <el-radio :label="0">拒绝</el-radio>
            <el-radio :label="2">取消</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="备注信息" prop="check_remark">
          <el-input v-model="form.check_remark" placeholder type="textarea" :rows="5"></el-input>
        </el-form-item>
      </el-form>
    </el-alert>
    <template #footer>
      <el-button type="primary" @click="onSubmit" :loading="saving">确定</el-button>
    </template>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      visible: false,
      saving: false,
      model: {
        refund: {
          order: {
            sku: {},
          },
        },
      },
      form: {
        result: 1,
      },
      rules: {
        check_remark: [{ required: true, message: "请输入", trigger: "blur" }],
      },
    };
  },
  props: ["id"],
  emits: ["close"],
  created() {},
  methods: {
    /**
     *
     */
    onOpen() {
      this.visible = true;
      this.loadDtl();
    },
    /**
     *
     */
    loadDtl() {
      this.loading = true;
      this.$http
        .get("seller/v1/mallRefund/pay/dtl?id=" + this.id)
        .then((res) => {
          if (res.code == 0) {
            this.model = res.data;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    /**
     * 提交保存
     */
    onSubmit() {
      this.form.id = this.id;
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$confirm("是否确认提交？", "提醒").then(() => {
            this.saving = true;
            this.$http
              .post("seller/v1/mallRefund/pay/check", this.form)
              .then((res) => {
                if (res.code == 0) {
                  this.$emit("close", true);
                }
              });
          });
        }
      });
    },
  },
};
</script>

<style >
.mall_refund_alert .el-alert__content {
  width: 100%;
}
</style>